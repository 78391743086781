import React from "react";
import Content from "./ComingSoon/Content";
import { useParams } from "react-router-dom";

function ComingSoon() {
  let { slug } = useParams();
  let contentType = "build";

  switch (slug) {
    case "build":
      contentType = "build";
      break;
    case "marketplace":
      contentType = "marketplace";
      break;
    default:
      contentType = "build";
      break;
  }
  return (
    <>
      <Content type={contentType} />
    </>
  );
}

export default ComingSoon;

import React from "react";
import "./Content.css";
import { Container, Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";
import isMobile from "../../functions/IsMobile";

function Content() {
  const items = [
    {
      reverse: false,
      bgColor: "linear-gradient(270deg, #6300B0 25.83%, #A700F5 113.75%)",
      name: "NFT",
      title: "Boost Your Status with NFTs",
      text: "Buy, sell, rent NFT's and have complete ownerships. You are in charge of your assets now!",
      btnText: "( Coming Soon )",
      imgSrc: "img/item1.png",
    },
    {
      reverse: true,
      bgColor: "linear-gradient(270deg, #AF1212 25.83%, #E34920 113.75%)",
      name: "Hub",
      title: "Future of Gaming ",
      text: "Choose a game from library or design & develop your own game. Welcome to new era of gaming ecosystem.",
      btnText: "( Coming Soon )",
      imgSrc: "img/wallet2.png",
    },
    {
      reverse: false,
      bgColor: "linear-gradient(270deg, #CD800C 25.83%, #E7AC0F 113.75%)",
      name: "$AGE",
      title: "Play, Win and Earn the $AGE's",
      text: "$AGE is the exchangeable, Solana based currency of MetaAge. All utilities inside the MetaAge is powered by $AGE",
      btnText: "( Coming Soon )",
      imgSrc: "img/item3.png",
    },
  ];

  return (
    <div className="About">
      <Container>
        {items.map((item, index) => {
          return <Builds props={item} />;
        })}
        <div className="cardItems">
          <Cards
            img="img/wallet1.png"
            title="Marketplace"
            text="Visit to buy and sell your best assets."
          />
          <Cards
            img="img/carditem3.jpg"
            title="Game Hub"
            text="Access all games from one simple platform"
          />
          <Cards
            img="img/carditem2.jpg"
            title="Build System"
            text="Design & build your own games with zero coding knowledge."
          />
        </div>
        <h2 className="teamTitle">Team</h2>
        <div className="teams">
          <Members img="img/team1.jpg" name="Mustafa Ekinci" text="Co-founder / Project Manager" />
          <Members img="img/team2.jpg" name="Ahmet  Eren Şahin" text="Co-founder/ Lead developer" />
          <Members img="img/team3.jpg" name="Berka Kınay" text="3D Artist" />
          <Members img="img/fıratcan.png" name="Fıratcan Yakut " text="2D Artist" />
          <Members img="img/NFTteam1.png" name="Hasan Turan" text="Game Designer" />
          <Members img="img/NFTteam2.png" name="Furkan Bacacı" text="Frontend Developer" />
          <Members img="img/NFTteam3.png" name="Figen Yılmaz" text="Game Developer" />
          <Members img="img/team4.jpg" name="Ömer Aşgın" text="Game Developer" />
        </div>
      </Container>

      

      <Container style={{display : "none"}}>
        {isMobile() ? (
          <Swiper
            pagination={false}
            slidesPerView={3}
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <img src="img/partner1.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner2.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner3.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner4.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner1.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner2.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner3.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/partner4.png" alt="" />
            </SwiperSlide>
          </Swiper>
        ) : (
          <div className="partners">
            <div className="title">Partners</div>
            <div className="partnerItems">
              <div className="item">
                <img src="img/partner1.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner2.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner3.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner4.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner4.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner3.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner2.png" alt="" />
              </div>
              <div className="item">
                <img src="img/partner1.png" alt="" />
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

function Builds(p) {
  return (
    <div
      className={`${p.props.reverse && "reverse"} cardBody`}
      style={{ background: p.props.bgColor }}
    >
      <div className="buildContents">
        <div className="buildName">{p.props.name}</div>
        <div className="buildTitle">{p.props.title}</div>
        <div className="buildTexts">{p.props.text}</div>
        {/* <button className="buildBtn">{p.props.btnText}</button> */}
      </div>
      <div className="cardImg">
        <img src={p.props.imgSrc} width="85%" alt="" />
      </div>
    </div>
  );
}

function Cards(params) {
  return (
    <Card className="cardItem">
      <Card.Img variant="top" src={params.img} />
      <Card.Body>
        <Card.Title>{params.title}</Card.Title>
        <Card.Text>{params.text}</Card.Text>
      </Card.Body>
      {/* <button className="cardBtn">Visit Marketplace</button> */}
    </Card>
  );
}

function Members(params) {
  return (
    <div className="members">
      <img src={params.img} alt="" className="memberImg" />
      <div className="memberInfo">
        <div className="title">{params.name}</div>
        <div className="text">{params.text}</div>
      </div>
    </div>
  );
}

export default Content;

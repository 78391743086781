import React from "react";
import Header from "./Home/Header";
import ComingSoon from "./Home/ComingSoon";
import Games from "./Home/GamesSection";
import Builds from "./Home/BuildSection";
import MarketSection from "./Home/MarketSection";
import EventSection from "./Home/EventSection";
import isMobile from "../functions/IsMobile";

function Home() {
  return (
    <>
      
      <Header isFull={0} />
      <Games />
      <Builds />
      <EventSection></EventSection>
      <MarketSection
        sectionId="market"
        bgImage="./img/marketLayer.jpg"
        title="Marketplace"
        subTitle="Everything in one place"
        text="In MetaAge, you can buy NFT's and takeover the ownership. Sell, use or rent your NFT's. You are in charge of your assets now!"
      />     
    </>
  );
}

export default Home;

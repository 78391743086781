import React from 'react'
import Content from "./About/Content"

function About() {
  return (
    <>
        <Content />
    </>
  )
}

export default About
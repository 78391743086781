import React, { useEffect, useState } from "react";
import "./content.css";
import { Container } from "react-bootstrap";

function Content(props) {
  const [bgImg, setbgImg] = useState("/img/buildcsbg.jpg");
  const [title, settitle] = useState(`
  <h2>No Limit to</h2>
  <h2>imagination</h2>
  `);
  const [desc, setdesc] = useState(`
  Create scenes, artworks, challenges, and more, using the simple builder interface, upload & release. Builders can learn more and grow their expertise in time.
  `);

  useEffect(() => {
    if (props.type == "marketplace") {
      setbgImg("/img/marketcsbg.jpg");
      settitle(`MetaAge Marketplace`);
      setdesc(`
      In MetaAge, you can buy NFT's and takeover the ownership. Sell, use or rent your NFT's. You are in charge of your assets now!
      `);
    } else if (props.type == "build") {
      setbgImg("/img/buildcsbg.jpg");
      settitle(`No Limit to imagination`);
      setdesc(`
      Create scenes, artworks, challenges, and more, using the simple builder interface, upload & release. Builders can learn more and grow their expertise in time.
      `);
    }
  }, []);

  return (
    <>
      <div className="header" style={{ background: `url(${bgImg})` }}>
        <div className="blackfilter d-lg-none d-static"></div>
        <Container>
          <div className="title">
            <h2>{title}</h2>
          </div>
          <div className="desc">
            <p>{desc}</p>
          </div>
        </Container>
      </div>

      <div className="cs-content">
        <div className="blackfilter2"></div>
        <div className="text">
          <h1>coming soon</h1>
        </div>
      </div>
    </>
  );
}

export default Content;

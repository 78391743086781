import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import style from "./PreFooter.module.css";
import footerLogo from "../images/footerLogo.png";
import axios from "axios";

function PreFooter() {
  const [mail, setMail] = useState(''); 
  const [mailResponse, setMailResponse] = useState(''); 
  const [mailMessage, setMailMessage] = useState(''); 
  const [inputVal, setinputVal] = useState(''); 
  const [currUrl, setCurrUrl] = useState(global.config.baseUrl);
  useEffect(() => {
    if (window.location.pathname == "/") {
      setCurrUrl("");
    } else {
      setCurrUrl(global.config.baseUrl);
    }
  }, []);

  const handleUserInput = (e) => {
    setinputVal(e.target.value);
  };

  function sendMail(email){
    if (email==null || email=='' || email==undefined) {
      setMailResponse(true);
      setMailMessage("Please write your email")
      return;
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      setMailResponse(true);
      setMailMessage("Invalid email")
      return;
    }
    axios.post('/mail/mail.php', {
      mailAddr : mail
    })
    .then(function (response) {
      if (response.data==true) {
        setinputVal('');
        setMailMessage("Thank You!! Your information has been successfully sent to us.")
      } else {
        setMailMessage("An error has occurred. Please try again later..");
      }
      setMailResponse(true);
    })
    .catch(function (error) {
      setMailResponse(true);
      setMailMessage("An unexpected error has occurred. Please try again later..");
    });
  }
  return (
    <>
      <div className={style.prefooter}>
        <Container>
          <Row className="g-5">
            <Col xs={12} md={6}>
              <h2 className={style.title1}>Contact us</h2>
              <p className={style.text1}>
              You can reach us via our 24/7 discord channel. In case of a different support need, you can send us an e-mail.
              </p>
              <button className={style.TouchBtn} type="button">
                Get In Touch
              </button>
            </Col>
            <Col xs={12} md={6}>
              <h2 className={style.title1}>Get Latest the Updates</h2>
              <p className={style.text1}>
              Subscribe to newsletter for keep updated about breaking news on events, competitions, new games and new developments.
              </p>


              <div className={style.footerForm}>   
                  <input placeholder="email@domain.com" type="email" onInput={e => setMail(e.target.value)} value={inputVal} onChange={handleUserInput}  />
                  <button type="button" onClick={()=>sendMail(mail)}>Sign Up</button>
              </div>
                  {mailResponse&&<p className={style.mailMessage}>{mailMessage}</p>}
            </Col>
          </Row>
        </Container>
      </div>
      <div className={style.footer}>
            <div className={style.footerLogo}>
                <img src={footerLogo} alt="Metaage footer logo" />
            </div>
            <div className={style.footerLinks}>
                <div className={style.links}>
                    <a href={global.config.baseUrl}><li>Home</li></a>
                    <a href={`${currUrl}/#games`}><li>Game Hub</li></a>
                    <a href={`${currUrl}/#market`}><li>Marketplace</li></a>
                    
                    <a href={`${currUrl}/#builds`}><li>Building</li></a>
                    <a href={"https://metaage.gitbook.io/metaage/roadmap"}><li>Roadmap</li></a>
                    
                    <a target="_blank" href="https://drive.google.com/file/d/1H9bwtisMS2wnhuBYmHUarJTFTqlZAyKa/view?usp=sharing"><li>Whitepaper</li></a>
                    <a href={`${global.config.baseUrl}/about`}><li>About</li></a>
                </div>

                <div className={style.socials}>
                    <a target="_blank" href="https://www.instagram.com/metaagee/"><img className={style.socialIcon} src="./img/Icon/icon-social-instagramsocial.png" alt="" /></a>
                    <a target="_blank" href="https://twitter.com/metaagee"><img className={style.socialIcon} src="./img/Icon/icon-social-twittersocial.png" alt="" /></a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCGG5wYCZeIJiMZxaPH7Vw3Q"><img className={style.socialIcon} src="./img/Icon/icon-social-youtubesocial.png" alt="" /></a>
                    <a target="_blank" href="https://www.reddit.com/user/MetaAge"><img className={style.socialIcon} src="./img/Icon/icon-redditsocial.png" alt="" /></a>
                    <a target="_blank" href="https://medium.com/@metaage"><img className={style.socialIcon} src="./img/Icon/icon-mediumsocial.png" alt="" /></a>
                    <a target="_blank" href="https://discord.gg/metaage"><img className={style.socialIcon} src="./img/Icon/icon-social-discordsocial.png" alt="" /></a>
                </div>
            </div>
        </div>
        <div className={style.endFooter}>
            <Container>
                <span>Copyright © 2022 The MetaAge All Rights Reserved</span>
            </Container>
        </div>
    </>
  );
}

export default PreFooter;

import React, { useEffect } from "react";
import "./Content.css";

function Content() {
  useEffect(() => {
    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);
  }, []);

  const isElementInViewport = (el) => {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const callbackFunc = () => {
    var items = document.querySelectorAll(".timeline li");
    for (var i = 0; i < items.length; i++) {
      if (isElementInViewport(items[i])) {
        items[i].classList.add("in-view");
      }
    }
  }

  return (
    <>
      <div   className="timeline">
        <ul >
          <li>
            <div>
              <time>
                <span>Q3-Q4</span> 2021
              </time>
              <text>
               <span> Examination of Metaverse, general drafting of the MetaAge world.</span> 
              </text>
            </div>
          </li>
          <li>
            <div>
              <time>
                <span>Q1</span>2022
              </time>
              <text>
                <h4>01-2022</h4>
               <span>The beginning of the virtual world, games and, assets.</span>
                <h4>02-03 2022</h4>
                <span>Creating social media accounts and a website. </span>
                <span>Announce partnership with more than 10 project. </span>
                <span>Integrating 15+ other project's character into the metaverse as avatars. </span>
                <span>Completing development of open alpha version of the metaverse. </span>
              </text>
            </div>
          </li>
          <li>
            <div>
              <time>
                <span>Q2</span>2022
              </time>
              <text>
                <h4>04-2022</h4>
                <span><strong> Open alpha activation of MetaAge game. </strong>
                <p style={{ color: "#ccc" }}>
                  (public access to our metaverse on our website)</p></span>  
                  <span> Access to f2p games in our metaverse. </span> 
                  <span> First launch of concert, cinema, meeting room. </span> 
                  <span> Access to our mini games. AGE token deploy on Solana network.</span>
                  <h4>05-2022</h4>
                    <span className="noCheck"> First launch of NFT based plots. </span>
                    <span className="noCheck"> Addition new F2P games. </span>
                    <span className="noCheck"> Deals with major NFT marketplaces.</span>
                  <h4>06-2022</h4>
                    <span className="noCheck"> Selling the plot NFT(5000 Supply). </span>
                    <span className="noCheck"> Closed alpha build system activation (exclusive release of closed alpha build system to a few early testers). </span>
                    <span> Launch of P2E games. </span>
                    <span className="noCheck"> AGE Token airdrop to holders. </span>
                    <span className="noCheck"> Activites and airdrops to plot holders. </span>
                    <span className="noCheck"> Addition of P2E games.</span>
              </text>
            </div>
          </li>
          <li>
            <div>
              <time>
                <span>Q3</span>2022
              </time>
              <text className="noCheck">
                <h4>07-2022</h4>
                <span>AGE token public sale (IDO). </span>
                <span>Open alpha build system activation ( Plot holders will be able to build shops, art galleries and structures). </span>
                <span>Addition of new F2P games. </span>
                <span>Closed alpha version activation of  pc and mobile version. (Closed alpha build system released exclusively for a few early testers.)</span>
                <span>Launch of game-maker system.</span>
                <h4>08-09-2022</h4>
                <span>Activities and airdrops to plot holders. </span>
                <span>Additions of new P2E games. </span>
                <span>Dealings with major marketplaces. </span>
                <span>Creating our own marketplace. </span>
              </text>
            </div>
          </li>
          <li>
            <div>
              <time>
                <span>Q4</span> 2022
              </time>
              <text className="noCheck">
                  <span style={{display : "block"}}> Activation of closed alpha version of game making system (closed alpha build system released exclusively to a few early testers.)</span>
                  <span> Various activites and agreements with partners</span>
              </text>
            </div>
          </li>
          <li>
            <div>
              <time>
                <span>Q1-Q2</span>2023
              </time>
              <text className="noCheck">
                <span style={{display : "block"}}><strong>Integration of VR system.</strong></span>
                <span>Game making open alpha version activation (open alpha game maker system, where people who own the plot can enter and play without needing any code)</span>
              </text>
            </div>
          </li>
          <li>
            <div>
              <time>
                <span>Q3-Q4</span>2023
              </time>
              <text className="noCheck">
                <strong><span> Alpha 2 update of MetaAge Metaverse.</span></strong>
              </text>
            </div>
          </li>
        </ul>
        
      </div>
      
    </>
  );
}

export default Content;

import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import style from "./GamesSection.module.css";
import ModalVideo from "react-modal-video";

function GamesSection() {
  const playButton = (
    <svg
      cursor="pointer"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 50.625C20.7343 50.625 14.7251 48.1359 10.2946 43.7054C5.86406 39.2748 3.375 33.2657 3.375 27C3.375 20.7343 5.86406 14.7251 10.2946 10.2946C14.7251 5.86406 20.7343 3.375 27 3.375C33.2657 3.375 39.2748 5.86406 43.7054 10.2946C48.1359 14.7251 50.625 20.7343 50.625 27C50.625 33.2657 48.1359 39.2748 43.7054 43.7054C39.2748 48.1359 33.2657 50.625 27 50.625ZM27 54C34.1608 54 41.0284 51.1554 46.0919 46.0919C51.1554 41.0284 54 34.1608 54 27C54 19.8392 51.1554 12.9716 46.0919 7.90812C41.0284 2.84464 34.1608 0 27 0C19.8392 0 12.9716 2.84464 7.90812 7.90812C2.84464 12.9716 0 19.8392 0 27C0 34.1608 2.84464 41.0284 7.90812 46.0919C12.9716 51.1554 19.8392 54 27 54Z"
        fill="white"
      />
      <path
        d="M21.1646 17.0606C21.4406 16.9185 21.7505 16.8553 22.0602 16.8779C22.3698 16.9005 22.6672 17.0081 22.9196 17.1889L34.7321 25.6264C34.9509 25.7825 35.1292 25.9886 35.2522 26.2275C35.3752 26.4664 35.4394 26.7313 35.4394 27C35.4394 27.2688 35.3752 27.5336 35.2522 27.7725C35.1292 28.0114 34.9509 28.2175 34.7321 28.3736L22.9196 36.8111C22.6673 36.9917 22.37 37.0993 22.0606 37.1219C21.7511 37.1445 21.4413 37.0814 21.1654 36.9394C20.8895 36.7974 20.6581 36.5821 20.4966 36.3172C20.3351 36.0522 20.2497 35.7478 20.25 35.4375V18.5625C20.2497 18.2523 20.3349 17.948 20.4962 17.683C20.6576 17.4181 20.8889 17.2027 21.1646 17.0606Z"
        fill="white"
      />
    </svg>
  );

  const [isOpen, setOpen] = useState(false);
  const [vidUrl, setUrl] = useState("");

  return (
    <>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={vidUrl}
        onClose={() => setOpen(false)}
      />
      <div className={`gamesSection ${style.gamesSection}`} id={'games'}>
        <Container>
          <Row className="pb-4 pt-4 gx-5">
            <Col xs={12} md={12} lg={6}>
              <Row className="g-4">
                <Col xs={8}>
                  <div className={style.itemBox}>
                    <img src="./img/video1.jpg" alt="" className="img-fluid" />
                    <div
                      onClick={() => {
                        setOpen(true);
                        setUrl("./img/RocketLeaegue.mp4");
                      }}
                    >
                      {playButton}
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="rightImg d-flex flex-column justify-content-between h-100">
                    <div className={style.itemBox2}>
                      <img src="./img/game1.jpg" alt="" className="img-fluid" />
                      <div
                        onClick={() => {
                          setOpen(true);
                          setUrl("./img/Race.mp4");
                        }}
                      >
                        {playButton}
                      </div>
                    </div>
                    <div className={style.itemBox2}>
                      <img src="./img/game2.jpg" alt="" className="img-fluid" />
                      <div
                        onClick={() => {
                          setOpen(true);
                          setUrl("./img/Zombi.mp4");
                        }}
                      >
                        {playButton}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className={style.itemBox2}>
                    <img src="./img/game3.jpg" alt="" className="img-fluid" />
                    <div
                      onClick={() => {
                        setOpen(true);
                        setUrl("./img/UzaySavaslari.mp4");
                      }}
                    >
                      {playButton}
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className={style.itemBox2}>
                    <img src="./img/game4.jpg" alt="" className="img-fluid" />
                    <div
                      onClick={() => {
                        setOpen(true);
                        setUrl("./img/Soccer_mainworld.mp4");
                      }}
                    >
                      {playButton}
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className={style.itemBox2}>
                    <img src="./img/game5.jpg" alt="" className="img-fluid" />
                    <div
                      onClick={() => {
                        setOpen(true);
                        setUrl("./img/Labirent.mp4");
                      }}
                    >
                      {playButton}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={6} className={style.g__texts}>
              <div className="mb-4">
                <h2 className={style.gamesTitle}>GAME HUB</h2>
              </div>
              <div className="g__subtitle my-4">
                <span className={style.gamesSubTitle}>
                  Dive into the innovative
                </span>
                <span className={style.gamesSubTitle}>
                  and evolving                  
                </span>
                <span className={style.gamesSubTitle2}>
                  web3 gaming platform!
                </span>
              </div>
              <div className="g__desc">
                <span className={style.gamesDesc}>
                  Explore now! All blockchain games in one simple platform... Add games into your library, download, play & earn. This is the future of gaming ecosystem!
                </span>
              </div>
              <div className={`${style.g__submit} mt-5`}>
                <a href="https://drive.google.com/file/d/1H9bwtisMS2wnhuBYmHUarJTFTqlZAyKa/view?usp=sharing" className={`${style.g__btn}`}>Whitepaper</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GamesSection;

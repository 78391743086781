import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import { Button, Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import styles from "./Navbar.module.css";
import isMobile from "../functions/IsMobile"

function Navibar(props) {
  //? CurrentUrl ana sayfada linkler content içinde move yapıyor, anasayfa dışında move yamayacağı için linklerden herhangi birine basıldıgında anasayfada değilse önce ana sayfaya dönmesi için eklendi
  const [currUrl, setCurrUrl] = useState(global.config.baseUrl);
  useEffect(() => {
    if (window.location.pathname == "/") {
      setCurrUrl("");
    } else {
      setCurrUrl(global.config.baseUrl);
    }
  }, []);
  return (
    <>
      <Navbar
        expand="lg"
        className={props.style == "1" ? styles.navTop : styles.navTopTrs}
      >
        <Container>
          <Navbar.Brand className="me-4">
            <a href={global.config.baseUrl}>
              <img src={logo} width="140" height="28" alt="" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className={styles.navCollapse}>
            <Nav className="me-auto my-2 my-lg-0">
              <Nav.Link className={styles.navLink} href={global.config.baseUrl}>
                Home
              </Nav.Link>
              <Nav.Link className={styles.navLink} href={`${currUrl}/#games`}>
                Game Hub
              </Nav.Link>
              <Nav.Link className={styles.navLink} href={`${currUrl}/#market`}>
                Marketplace
              </Nav.Link>
              
              <Nav.Link className={styles.navLink} href={`${currUrl}/#builds`}>
                Building
              </Nav.Link>
              
              
              <Nav.Link
                className={styles.navLink}
                href={`${global.config.baseUrl}/about`}
              >
                About
              </Nav.Link>
            </Nav>

           {/*  <button className={`btn ${styles.btnGs}`}>Get Started</button> */}
            <a
              href={isMobile()? `${global.config.baseUrl}/not-support` : "https://play.metaage.app/"}
              className={`btn ${styles.btnLp}`}
            >
              Let's Play
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navibar;

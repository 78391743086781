import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./ComingSoon.css";
import isMobile from "../../functions/IsMobile"
import axios from "axios"

function ComingSoon() {

    const [mail, setMail] = useState(''); 
    const [mailResponse, setMailResponse] = useState(''); 
    const [mailMessage, setMailMessage] = useState(''); 
    const [inputVal, setinputVal] = useState(''); 
    const [second , setSecond] = useState("0");
    const [minute , setMinute] = useState("0");
    const [hour , setHour] = useState("0");
    const [day , setDay] = useState("0");
    const [launchDate , setLaunchDate] = useState(new Date('Apr 15, 2022 13:00:00').getTime());
/*     useEffect(()=>{


        const intvl = setInterval(() => {
            const now = new Date().getTime();
            const distance = launchDate - now;
            setDay(Math.floor(distance / (1000 * 60 * 60 * 24)));
            setHour(Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ));
            setMinute(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            setSecond(Math.floor((distance % (1000 * 60)) / 1000));
          }, 1000);

          
    },[]) */

    const handleUserInput = (e) => {
        setinputVal(e.target.value);
      };
    
      function sendMail(email){
        if (email==null || email=='' || email==undefined) {
          setMailResponse(true);
          setMailMessage("Please write your email")
          return;
        }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
          setMailResponse(true);
          setMailMessage("Invalid email")
          return;
        }
        axios.post('/mail/mail.php', {
          mailAddr : mail
        })
        .then(function (response) {
          if (response.data==true) {
            setinputVal('');
            setMailMessage("Thank You!! Your information has been successfully sent to us.")
          } else {
            setMailMessage("An error has occurred. Please try again later..");
          }
          setMailResponse(true);
        })
        .catch(function (error) {
          setMailResponse(true);
          setMailMessage("An unexpected error has occurred. Please try again later..");
        });
      }

  return (
    <>
      <div className="mainContent">
          <div className="bluring1"></div>
          <div className="bluring2"></div>
          <div className="bluring3"></div>
        <Container>
          <div className="contentItems">
            <div className="csContentTexts">
              <h1>WE ARE COMING SOON</h1>
              <p>
                Take your place in the future of gaming experience!
              </p>
            </div>

            <div className="tba">
              <h3>PUBLIC SALE: TBA</h3>
              
              <a className="playDemo" href={"https://drive.google.com/file/d/1H9bwtisMS2wnhuBYmHUarJTFTqlZAyKa/view?usp=sharing"} >Whitepaper</a>
              <img className="gotoArrow" src={`${global.config.baseUrl}/img/arrow-new.png`} alt="arrow icon" />
              <div className="pdfu">
              <span>Follow Us</span>
              <a target="_blank" href="https://twitter.com/metaagee" ><img src={`${global.config.baseUrl}/img/twitter-new.png`} alt="twitter icon" /></a>
              {/* <a target="_blank" href="https://t.me/MetaAgeApp"><img src={`${global.config.baseUrl}/img/telegram-new.png`} alt="telegram icon" /></a> */}
              <a target="_blank" href="https://discord.gg/metaage"><img src={`${global.config.baseUrl}/img/discord-new.png`} alt="discord icon" /></a>
              </div>
            </div>

            <div className="count-down d-none">
              <div class="box">
                <h3 id="day">{day}</h3>
                <span>Days</span>
              </div>
              <div class="box">
                <h3 id="hour">{hour}</h3>
                <span>Hours</span>
              </div>
              <div class="box">
                <h3 id="mintue">{minute}</h3>
                <span>Minutes</span>
              </div>
              <div class="box">
                <h3 id="second">{second}</h3>
                <span>Second</span>
              </div>
            </div>

            <div className="csForm d-none">   
                  <input placeholder="email@domain.com" type="email" onInput={e => setMail(e.target.value)} value={inputVal} onChange={handleUserInput}  />
                  <button type="button" onClick={()=>sendMail(mail)}>Subscribe</button>
            </div>
                  {mailResponse&&<p className="mailMessage">{mailMessage}</p>}
            <div className="socials d-none">
                <a target="_blank" href="https://www.youtube.com/channel/UCGG5wYCZeIJiMZxaPH7Vw3Q"><img className="socialIcon" src="./img/Icon/icon-social-youtubesocial.png" alt="" /></a>
                <a target="_blank" href="https://twitter.com/metaagee"><img className="socialIcon" src="./img/Icon/icon-social-twittersocial.png" alt="" /></a>
                <a target="_blank" href="https://www.instagram.com/metaagee/"><img className="socialIcon" src="./img/Icon/icon-social-instagramsocial.png" alt="" /></a>
                <a target="_blank" href="https://www.reddit.com/user/MetaAge"><img className="socialIcon" src="./img/Icon/icon-redditsocial.png" alt="" /></a>
                <a target="_blank" href="https://medium.com/@metaage"><img className="socialIcon" src="./img/Icon/icon-mediumsocial.png" alt="" /></a>
                <a target="_blank" href="https://discord.gg/metaage"><img className="socialIcon" src="./img/Icon/icon-social-discordsocial.png" alt="" /></a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ComingSoon;

import React from 'react'
import Content from './Roadmap/Content'

function Roadmap() {
  return (
    <>
        <Content />
    </>
  )
}

export default Roadmap
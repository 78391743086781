import React from "react";
import { Container } from "react-bootstrap";
import style from "./NotFound.module.css";

function NotFound() {
  return (
    <Container>
      <div className={style.content}>
        <div className={style.contentText}>
          <div className={style.contentTitle}>
            <h1 style={{ color: "red" }}>404</h1>
          </div>
          <div className={style.contentDesc}>
            <h4>Oops! Page not found.</h4>
            <p>The page you ara looking for was not found.</p>
            <p>You may return to home page</p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default NotFound;

import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import style from "./MarketSection.module.css";

function MarketSection(props) {
  return (
    <>
    <div className={style.content} style={{background: `url(${props.bgImage})`}} id={props.sectionId}>
        <div className={style.contentTitle}>
            <h2>Marketplace</h2>
        </div>
        <div className={style.contentTexts}>
            <h3>{props.subTitle}</h3>
            <p>In MetaAge, you can buy NFT's and takeover the ownership. Sell, use or rent your NFT's. You are in charge of your assets now!  </p>
        </div>
        
    </div>
    </>
  )
}

export default MarketSection
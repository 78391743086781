import React from "react";
import { Container } from "react-bootstrap";
import style from "./NotFound.module.css";

function NoSupport() {
  return (
    <div>
      <Container>
        <div className={style.content} style={{margin : "100px 0"}}>
          <div className={style.contentText}>
            <div className={style.contentTitle}>
              <h2 style={{ color: "red" }}>Sorry</h2>
            </div>
            <div className={style.contentDesc}>
              <h4>Mobile device is not support :( </h4>
              <p>This version is not supported in mobile devices. Please try to access from a desktop.</p>
              <p>You may return to home page</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default NoSupport;

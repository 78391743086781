import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import style from "./Header.module.css";
import isMobile from "../../functions/IsMobile";

function Header(prop) {

  const Video = (props) => {
    return (
      <video className={style.video} autoPlay muted loop>
        <source src={props.videoUrl} type={props.type} />
      </video>
    );
  };

  return (
    <header className={prop.isFull ? style.headerFull : style.header}>
      {!isMobile() && <Video videoUrl="./img/headerVid.mp4"/>}
      <div className={style.mobileGraident}></div>
      <Container className={`p-0 h-100 ${style.headContainer}`}>
        <Row className="align-items-center justify-content-center h-100">
          <Col lg={12}>
            <div className="mb-lg-0 text-center text-lg-start">
              <h1 style={{color:"white",fontSize:60,fontWeight:"bold"}}>
                Welcome to
              </h1>
              <h1 style={{color:"blueviolet",fontWeight:"bold",fontSize:80}}>
                MetaAge
              </h1>
              <p style={{color:"white",fontSize:60,width:"400px"}}>
                MetaAge is a web3 platform which contains game hub, advisory & development services and AAA quality P2E games.
              </p>
              
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;

import React from "react";
import "./content.css";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../images/footerLogo.png";

function Tokenomics() {
  return (
    <div className="token_content">
      <div className="token_header">
        <img src="./img/tokenomics_header.jpg" alt="" />
      </div>
      <div className="headerLogo">
        <img src={logo} alt="" />
        <span>Tokenenomic</span>
      </div>

      <div className="page1">
        <div className="page1Card">
          <div className="cardContent">
            <img src="./img/token_cardimg_1.jpg" className="img-fluid" alt="" />
            <span>
              $AGE is Solana based fungible spl-token and it is used for in-game
              transfers.
            </span>
          </div>
        </div>

        <div className="tableOfContents">
          <div className="contentsList">
            <h4>Table of Contents</h4>
            <ul>1-What is the MetaAge</ul>
            <ul>
              <span className="title bold">2)Virtual World Economics</span>
              <li>2.1-Build system</li>
              <li>2.2-Gamer maker system</li>
              <li>2.3-Marketplace</li>
              <li>2.4-P2E&F2P Games</li>
              <li>2.5-Real Estate Investments</li>
            </ul>
            <ul>
              <span className="title bold">3) AGE Token</span>
              <li> 3.1-What is the $AGE ?</li>
            </ul>
            <ul>
              <span className="title bold">4)Tokenomic</span>
              <li>4.1-$AGE Supply Distribution</li>
              <li>4.1.1-Community Pool</li>
              <li>4.1.2-Team Pool</li>
              <li>4.1.3-Treasury & yLiquidity</li>
              <li>4.1.4-Game Rewards</li>
              <li>4.1.5-Staking Pool</li>
              <li>4.1.5.1-Land Stake Pool(Unlocked)</li>
              <li> 4.1.5.2-$AGE sStake Pool(Locked-Unlocked)</li>
              <li>4.1.5.3-Missions(Locked)</li>
            </ul>
            <ul>
              <span className="title bold">5) $AGE Flow Scheme</span>
              <li>• 5.1- Earning $AGE</li>
              <li>• 5.1.1- P2E Games</li>
              <li>• 5.1.2- Land Stake Pool</li>
              <li>• 5.1.3- Native Token Stake Pool</li>
              <li>• 5.1.4- Missions Stake Pool</li>
              <li>• 5.1.5- Airdrops</li>
              <li>• 5.1.6- Events</li>
            </ul>
            <ul>
              <span className="title">5.2- Expending $AGE</span>
              <li>• 5.2.1- Voting</li>
              <li>• 5.2.2- Buying NFTs</li>
              <li>• 5.2.3- Leveling Up NFTs</li>
              <li>• 5.2.4- P2E Games</li>
              <li>• 5.2.5- Listing NFTs</li>
              <li>• 5.2.6- Raffles / Auctions</li>
              <li>• 5.2.7- Merging Lands</li>
              <li>• 5.2.8- Accessing Events</li>
              <li>• 5.2.9- Maintances</li>
            </ul>
          </div>
          <div className="imagList">
            <img src="./img/page1bg.png" className="img-fluid" alt="" />
            <img src="./img/page1bg2.png" className="img-fluid" alt="" />
            <img src="./img/page1bg3.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>

      <div className="page2">
        <Container>
          <div className="token_header">
            <img src="./img/tokenomics_header.jpg" alt="" />
          </div>
          <div className="page2_content">
            <h2>1) What Is The MetaAge?</h2>
            <p>
              MetaAge is a virtual world where you can socialize with your
              friends or other players in places such as amusement parks,cinema,
              night club, concert hall, meeting rooms, mini games etc. Also
              there are more complex games like spaceship wars, car soccer and
              many more. Playing some of those games will be completely free to
              play and there are some games in P2E concept. MetaAge has
              partnerships with more than 35 projects and integrated the
              characters of more than 35+ projects as playable avatars so that
              everyone can get to explore the lands & games inside the metaverse
              with their favorite project’s character. Land owners will be able
              to build buildings & games with simple drag & drop UI so even
              users with 0 coding knowledge will be able to use these systems.
              Accessing MetaAge metaverse is completely free and offers both
              “Connect Wallet” and “Join as Guest” options to login. Open alpha
              version of the MetaAge metaverse is live now with both P2E & F2P
              games in it!
            </p>
          </div>
        </Container>
      </div>

      <div className="page3">
        <Container>
          <h2>2) Virtual World Economics</h2>
          <Row
            style={{ justifyContent: "center", alignItems: "center", gap: 15 }}
          >
            <Col sm={12} md={3}>
              <img
                src="./img/wallet1.png"
                className="img-fluid"
                width="100%"
                alt=""
              />
            </Col>
            <Col sm={12} md={8}>
              <p>
                AGE token is the currency of this virtual world. Money in
                virtual worlds is backed by utility tokens. MetaAge prefered
                Solana in this area. MetaAge provides its users with a virtual
                business model and earnings. We are excited about the
                opportunity to create new playerbacked business models. Economy,
                both in terms of revenue model and token model! $AGE will
                provide instant liquidity to users and investors. The valuation
                of the $AGE token is calculated based on which players in our
                metaverse are able to build/play/ trade/earn/stake/p2e games. We
                target the economy of users buying, renting, investing in real
                estate, p
              </p>
            </Col>
            <Col sm={12} className="mt-4">
              <p>
                <span className="fw-bold" style={{ color: "#3b447e" }}>
                  2.1-Build system
                </span>
                : Users can build on their plots (Metaplex NFT same as erc-721)
                on a different scene either free of charge within the MetaAge
                building system or by purchasing from the marketplace. Users can
                create shops, art galleries or any structure they want, and they
                can do it simply with drag-and-drop. Users can profit from this
                system. Quantity of the building system assets will increase
                according to level of the land NFTs
              </p>
            </Col>
            <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <img src="./img/page3img.jpg" className="p3Img" alt="" />
            </Col>

            <Col xs={12}>
              <p>
                <span className="fw-bold" style={{ color: "#3b447e" }}>
                  2.1-Game maker:
                </span>{" "}
                When the system boots up, users that have plot NFTs( Metaplex
                NFT same as ERC-721) will be able to build games that have
                perfect game mechanics by using the AI game objects and
                monetizing it. Users can build those games by using the assets
                that are provided by MetaAge or they can buy assets from the
                marketplace. According to the land(NFT) level, the number of new
                inventories that can be used increases.
              </p>
              <p>
                <span className="fw-bold" style={{ color: "#3b447e" }}>
                  2.2- Marketplace:
                </span>{" "}
                MetaAge web-based marketplace allows users to upload, publish
                and sell assets (inventories) are sold with $AGE token.
              </p>
              <p>
                <span className="fw-bold" style={{ color: "#3b447e" }}>
                  2.3-P2E Games:
                </span>{" "}
                Users will spend & earn $AGE while playing both the AAA games
                that are built by MetaAge developers or the games that are built
                by the community.
              </p>
              <p>
                <span className="fw-bold" style={{ color: "#3b447e" }}>
                  2.4-Real Estate Investments:
                </span>{" "}
                Users are able to invest in real life assets(apartments, lands
                etc.) to earn a % from the monthly rent price. They can sell /
                buy this assets via TremGlobal’s virtual sales office located
                inside the MetaAge metaverse.
              </p>
            </Col>
            <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <img
                src="./img/tokenTg.jpg"
                className="img-fluid"
                style={{ borderRadius: 25 }}
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="page4">
        <div className="page4_content">
          <h2>Age Token</h2>
          <p>
            <h5>What is the $AGE ?</h5>$AGE is an important piece and currency
            of MetaAge’s virtual world. $AGE is Solana based fungible spl-token
            and it is used for in-game transfers. Users can collect $AGE to play
            certain P2E games, buy specific equipments, build system & game
            maker system inventories, various equipment and accessories from the
            marketplace for their avatars and gameplay. $AGE is spent and earned
            in many various ways. $AGE is also being used to upload or purchase
            assets from the marketplace. It’s main features are listed in the
            table below:
          </p>
          <div className="listItem active">
            <span>Blockchain</span>
            <span>SOLANA</span>
          </div>
          <div className="listItem">
            <span>Symboll</span>
            <span>AGE</span>
          </div>
          <div className="listItem">
            <span>Total Supply</span>
            <span>1,000,000,000</span>
          </div>
          <div className="listItem">
            <span>Decimals</span>
            <span>9</span>
          </div>
          <div className="listItem">
            <span>Address</span>
            <span></span>
          </div>
          <div className="listItem">
            <span>Token Model</span>
            <span>UTILITY</span>
          </div>
          <img
            src="./img/token_coin.jpg"
            className="img-fluid ms-4"
            width="70%"
            alt=""
          />
        </div>
      </div>

      <div className="page5">
        <Container>
          <h2>4) TOKENOMICS</h2>
          <Col xs={12}>
            <div className="imgBox">
              <img src="./img/tokenomics1.jpg" alt="" />
            </div>
          </Col>
          <Col xs={12}>
            <div className="imgBox">
              <img src="./img/tokenomics2.jpg" width="75%" alt="" />
            </div>
          </Col>

          <Col xs={12}>
            <p>
              <h3>4.1.1- Community Pool:</h3>
              MetaAge is a project that exists for the community. MetaAge team
              will work harder every day to grow the community and increase its
              possibilities. Community pool exists for funding: new P2E games
              every month, metaverse and social media events with giveaways,
              airdrops to holders, new partners, new community members and much
              more. The community pool will cover these and more.
            </p>
            <p>
              <h3>4.1.2-Team Pool:</h3>
              In keeping with the team’s belief and commitment to the future of
              their project, it will be in a locked period of 36-74 months. The
              MetaAge team has a broad vision and a quality team to build a
              livable virtual world.
            </p>
            <p>
              <h3>4.1.3-Treasury&Likilite Pool:</h3>
              MetaAge has a big and experienced team. These are game developers,
              graphics team, blockchain developers, social media and marketing
              team and much more. MetaAge will use its treasure to create a
              better virtual world for its users and holders. MetaAge has
              already started to grow its team and sign with different game
              companies. For new investors, partners, companies, game companies,
              MetaAge will use the treasury.
            </p>
            <p>
              <h3>4.1.4-Game rewards: </h3>
              AAA quality P2E games will be added in the MetaAge, with a new one
              added every month. Currently, there is an active P2E game and
              three F2P games. In addition, land (nft) owners will be able to
              create P2E games with the game maker system. First of all, these
              earnings of the user will be supplied from the game rewards pool.
              Once in the revolving fund, the remainder of the game rewards pool
              will be burned, excluding the cost of the surprise rewards.
            </p>
            <p>
              <h3>4.1.5-STAKING Pool Staking havuzu:</h3>
              It will be used to reward stakeholders who maintain the ecosystem.
              The MetaAge staking system is divided into three categories: the
              unlocked NFT stake pool, locked-unlocked native token stake pool,
              and locked missions stake pool.
            </p>
            <p>
              <h3>4.1.5.1-Land Stake Pool ( Unlocked )</h3>
              Users in the unlocked staking pool will earn $AGE tokens on a
              daily basis, proportional to their time in the pool. Users in this
              unlocked staking pool can leave the pool at any time and withdraw
              their $AGE tokens. MetaAge lands are divided into 3 different
              levels and the staking reward for each land level varies depending
              on the level. Users can upgrade their lands in return for $AGE.
            </p>
            <div className="math">
              <span>Land Stake Reward</span>
              <span>
                (Base stake reward * Land Level ) + (Supply / (1000 * Rarity) )
              </span>
            </div>
            <p>
              <h3>4.1.5.2$AGE Stake Havuzu (Locked - Unlocked)</h3>$AGE holders
              can store their $AGE tokens in the staking pool in exchange for an
              annual rate of return. Available in locked and unlocked options,
              the $AGE staking pool has different annual rates of return
              depending on the options.
            </p>
            <p>
              <h3>4.1.5.3- Missions Stake Pool ( Locked )</h3>
              Non-land NFT owners can send their NFTs on missions and sell the
              valuable parts they bring back. With different lock periods of 7
              days, 14 days and 30 days, this staking pool will have a rate of
              return based on the user’s preferred lock period. At the end of
              the lock period, the user will be able to exit the pool and
              collect their rewards.
            </p>
          </Col>
        </Container>
      </div>

      <div className="page6">
        
      </div>
    </div>
  );
}

export default Tokenomics;

import "./App.css";
import Navbar from "./partials/Navbar";
import PreFooter from "./partials/PreFooter";

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import LetsPlay from "./components/LetsPlay";
import NotFound from "./components/NotFound";
import ComingSoon from "./components/ComingSoon";
import RoadMap from "./components/Roadmap";
import About from "./components/About";
import NoSupport from "./components/NoSupport";
import Tokenomics from "./components/Tokenomics/Tokenomics";


function App() {
  //? fluidNavLinks bazı sayfalarda tasarımda transparan navbar oldugu için koyuldu 
  const [fluidNavbar, setNavProp] = useState(false);
  const fluidNavLinks = ["/LetsPlay","/comingsoon","/comingsoon/marketplace","/comingsoon/build"];
  useEffect(()=>{
    if(window.location.pathname=="/"){
      setNavProp(false);
    } else if(fluidNavLinks.includes(window.location.pathname)) {
      setNavProp(true);
    } else {
      setNavProp(false);
    }
  },[])
  
  return (
    <div className="App">
      <Navbar style={fluidNavbar ? "2" : "1"} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/LetsPlay" element={<LetsPlay />} />
          <Route exact path="/comingsoon" element={<ComingSoon />} />
          <Route exact path="/comingsoon/:slug" element={<ComingSoon />} />
          <Route exact path="/roadmap" element={<RoadMap />} />
          <Route exact path="/tokenomics" element={<Tokenomics />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/not-support" element={<NoSupport />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <PreFooter />
    </div>
  );
}

export default App;

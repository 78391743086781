import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import style from "./BuildSection.module.css";

function BuildSection() {
  return (
    <>
      <div className={style.buildSection} id={'builds'}>
        <Container className={`${style.sectionContainer} h-100`}>
          <div className={style.sectionContent}>
            <div className={style.sectionTitle}>
              <h2>BUILD YOUR OWN GAMES</h2>
            </div>
            <div className={style.SectionTexts}>
              <h3>No limit to</h3>
              <h3 style={{color: "pink"}}>imagination</h3>
              <p>
                Create scenes, artworks, challenges, and more, using the simple
                builder interface, upload & release. Builders can learn more and grow their expertise in time.
              </p>
            </div>
            
          </div>
          <div className={style.contentImg}>
              <img src="./img/building.png" className="img-fluid" width="70%" alt="" />
          </div>
        </Container>
      </div>
    </>
  );
}

export default BuildSection;

import React, { useEffect, useState } from "react";
import style from "./EventSection.module.css";
import isMobile from "../../functions/IsMobile";
import contentImg from "../../images/building.png";
import { Container, Card } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

function EventSection() {

  const data = [
    {
      id: 1,
      img: "img/NFT1.png",
      date: "",
      founder: "Missions",
      title: "Send your CyberGen to missions(same as staking) to collect goods from the universe. ",
    },
    {
      id: 2,
      img: "img/NFT2.png",
      date: "TBA",
      founder: "Games",
      title: "Join to epic story of CyberGen, have fun and earn $AGE",
    },
    {
      id: 3,
      img: "img/NFT3.png",
      date: "TBA",
      founder: "Airdrops",
      title: "Benefit from new airdrops such as new races, skins, comic books",
    },
  ];

  const Items = data.map((item) => {
    return (
      <Card className={`border-0 ${style.cardStyle}`} key={item.id}>
        <Card.Img className={style.cardImage} variant="top" src={item.img} />
        <Card.Body>
          <Card.Title className={style.founderText}>{item.founder}
            
            
          </Card.Title>
          <Card.Text className={style.cardText}>{item.title}</Card.Text>
        </Card.Body>
      </Card>
    );
  });

  const MobileSwiper = (
    <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
      {data.map((item) => {
        return (
            <SwiperSlide key={item.id}>
              <img src={item.img} alt="" />
              <span className={style.swiperText}>
                {item.date + " | " + item.founder}
              </span>
            </SwiperSlide>
        );
      })}
    </Swiper>
  );

  /* src={ require('./images/logo1.png).default} */
  /* public klasörüne import etmeden ulaşabilirsin ama direk ./images/logo1.png kullan ../public/... yok */
  /* Remote İmage için -> src ye direk url yi girerek */
  /* imageslerin yanında bi js dosyası oluştur. içine const Images = { logo1 : require('./logo-1.png).default , logo2 : req...} export default Images yap ve ana js den import et ve Images.logo1 gibi çağır */
  return (
    <>
      <div className={style.content} id={'events'}>
          <div className={style.contentTitle}>
            <h2>CyberGen NFT's</h2>
          </div>
          <div className={style.contentCards}>
            {isMobile() ? MobileSwiper : Items}
          </div>
{/*          <div className={style.comingSoon}>
          <h1>Coming Soon</h1>
        </div> */}
      </div>
    </>
  );
}

export default EventSection;
